<template>
  <div class="w-full max-h-screen bg-background h-full">

    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>

    <page-header
        backTo="contacts"
        title="Edit Contact"
        :info="contact.account_reference"
    >
      <!-- <button
        @click="changeViewMode"
        type="button"
        class="flex items-center bg-teal-200 border hover:bg-teal-300 border-teal-400 hover:border-teal-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <rect width="22" height="14" x="1" y="5" rx="7" ry="7" />
          <circle cx="16" cy="12" r="3" />
        </svg>
        <span>Sales view</span>
      </button> -->
      <button
          @click="showingCreateTicketPanel = true"
          class="mr-2 flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z"/>
          <path
              d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
          />
        </svg>
        <span>Create Ticket</span>
      </button>

      <AppButton color="blue" label="Print Contact" @click="printContactReport" :icon=true class="mr-2">
        <template v-slot:icon>
          <Icon iconType="print" class="mr-2"/>
        </template>
      </AppButton>
    </page-header>
    <main class="p-6 pb-20 bg-background flex-1 overflow-y-scroll h-full">
      <div v-if="contact">
        <div class="flex w-full space-x-4">

          <div class="w-5/12 flex flex-col">
            <div class="w-full bg-white p-5 rounded shadow-md flex flex-1">
              <div class="w-full">
                <h2 class="text-3xl mb-4">
                  {{ contact.name }}
                  <button
                      @click="openEditPanel"
                      class="ml-4 text-secondary bg-white p-1 rounded border border-transparent hover:border-secondary active:border-secondary active:shadow-inner active:bg-gray-200 transition duration-100"
                  >
                    <svg
                        class="stroke-current h-6 w-6"
                        fill="none"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                    >
                      <path
                          d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                      />
                    </svg>
                  </button>
                </h2>

                <!-- Default address -->
                <div
                    v-if="contact.addresses && contact.addresses.length > 0"
                    class="flex items-center py-1"
                >
                  <svg
                      class="text-orange-700 stroke-current h-5 w-5 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z"/>
                    <circle cx="12" cy="10" r="3"/>
                  </svg>
                  <p class="rounded-lg px-2 py-1">
                    {{ formatDefaultAddress() }}
                  </p>
                </div>
                <!-- Default address -->

                <div v-if="contact.people && contact.people.length > 0">
                  <div
                      v-if="
                      contact.people[0].firstname || contact.people[0].lastname
                    "
                      class="flex items-center py-1"
                  >
                    <svg
                        class="text-orange-700 stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                    >
                      <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"/>
                      <circle cx="12" cy="7" r="4"/>
                    </svg>
                    <p
                        v-if="contact.people[0].job_title"
                        class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2"
                    >
                      {{ contact.people[0].job_title }}
                    </p>
                    <p class="rounded-lg px-2 py-1">
                      <span v-if="contact.people[0].firstname">{{
                          contact.people[0].firstname + " "
                        }}</span>
                      <span v-if="contact.people[0].lastname">{{
                          contact.people[0].lastname
                        }}</span>
                    </p>
                  </div>
                  <div
                      v-if="contact.people[0].email"
                      class="flex items-center py-1"
                  >
                    <svg
                        class="text-orange-700 stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                    >
                      <path
                          d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                      />
                      <path d="M22 6l-10 7L2 6"/>
                    </svg>
                    <a
                        class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                        :href="'mailto:' + contact.people[0].email"
                    >{{ contact.people[0].email }}</a
                    >
                  </div>
                  <div
                      v-if="
                      contact.people[0].mobile || contact.people[0].telephone
                    "
                      class="flex items-center py-1"
                  >
                    <svg
                        class="text-orange-700 stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                    >
                      <path
                          d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"
                      />
                    </svg>
                    <a
                        v-if="contact.people[0].mobile"
                        class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                        :href="'tel:' + contact.people[0].mobile"
                    >{{ contact.people[0].mobile }}</a
                    >
                    <a
                        v-if="contact.people[0].telephone"
                        class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                        :href="'tel:' + contact.people[0].telephone"
                    >{{ contact.people[0].telephone }}</a
                    >
                  </div>

                  <div v-if="contact.website" class="flex items-center py-1">
                    <svg
                        class="text-orange-700 stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                    >
                      <circle cx="12" cy="12" r="10"/>
                      <path
                          d="M2 12h20M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10z"
                      />
                    </svg>
                    <a
                        class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                        :href="'//' + contact.website"
                        target="_blank"
                    >
                      {{ contact.website }}
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="w-2/12 flex flex-col bg-white py-5 px-2 rounded shadow-md">
            <label class="uppercase font-semibold text-sm mb-2">STATUS</label>
            <div class="flex w-full">
              <!-- First Column -->
              <div class="w-1/2 flex flex-col space-y-4 pr-1">
                <!-- Customer Status -->
                <div :class="{'border-2 text-sm px-2 py-3 leading-none rounded-lg select-none w-full text-center box-border flex items-center justify-center': true,
                    'border-gray-400 text-gray-400': !contact.is_customer,
                    'border-blue-600 text-blue-700 font-bold': contact.is_customer}">
                  CUSTOMER
                </div>

                <!-- Supplier Status -->
                <div :class="{'border-2 text-sm px-2 py-3 leading-none rounded-lg select-none w-full text-center box-border flex items-center justify-center': true,
                    'border-gray-400 text-gray-400': !contact.is_supplier,
                    'border-blue-600 text-blue-700 font-bold': contact.is_supplier}">
                  SUPPLIER
                </div>

                <!-- On Hold Status -->
                <div :class="{'border-2 text-sm px-2 py-3 leading-none rounded-lg select-none w-full text-center box-border flex items-center justify-center': true,
                    'bg-gray-400 border-gray-400 text-white': !contact.is_on_hold,
                    'bg-red-600 border-red-600 text-white': contact.is_on_hold}">
                  ON HOLD
                </div>

                <!-- Overdue Status -->
                <div :class="{'border-2 text-sm px-2 py-3 leading-none rounded-lg select-none w-full text-center box-border flex items-center justify-center': true,
                    'bg-gray-400 border-gray-400 text-white': !contact.is_overdue,
                    'bg-red-600 border-red-600 text-white': contact.is_overdue}">
                  OVERDUE
                </div>
              </div>

              <!-- Second Column -->
              <div class="w-1/2 flex flex-col space-y-4 pl-1">
                <!-- Distributor Status -->
                <div :class="{'border-2 text-sm px-2 py-3 leading-none rounded-lg select-none w-full text-center box-border flex items-center justify-center': true,
                    'border-gray-400 text-gray-400': !contact.is_distributor,
                    'border-blue-600 text-blue-700 font-bold': contact.is_distributor}">
                  DISTRIBUTOR
                </div>

                <!-- Payment Terms Status -->
                <div :class="{
                    'border-2 text-sm px-2 py-3 leading-none rounded-lg select-none w-full text-center box-border flex items-center justify-center': true,
                    'bg-gray-400 border-gray-400 text-white': contact.payment_terms !== 'COD - Cash on Delivery' && contact.payment_terms !== 'Pre-Pay',
                    'bg-red-600 border-red-600 text-white': contact.payment_terms === 'COD - Cash on Delivery' || contact.payment_terms === 'Pre-Pay'
                }">
                  <span v-if="contact.payment_terms === 'COD - Cash on Delivery'">COD</span>
                  <span v-else-if="contact.payment_terms === 'Pre-Pay'">Pre-Pay</span>
                  <span v-else>COD ONLY</span>
                </div>


                <!-- Deactivation Status -->
                <div :class="{
                 'border-2 text-sm px-2 py-3 leading-none rounded-lg select-none w-full text-center box-border flex items-center justify-center': true,
                 'bg-green-400 border-green-500 text-white': !contact.is_deactivated,
                 'bg-gray-600 border-gray-600 text-white': contact.is_deactivated
                 }">
                  <span v-if="contact.is_deactivated">DEACTIVATED</span>
                  <span v-else>ACTIVE</span>
                </div>



                <!-- Amount Status -->
                <div :class="{
                           'border-2 text-sm px-2 py-3 leading-none rounded-lg select-none w-full text-center box-border flex items-center justify-center font-bold': true,
                           'border-gray-400 text-gray-400': !contact.account_balance || parseFloat(contact.account_balance) <= 0,
                            'border-red-600 text-red-600': parseFloat(contact.account_balance) > 0
    }">
                  {{ formatCurrency(contact.account_balance) }}
                </div>


              </div>
            </div>
          </div>



          <div class="w-5/12 flex flex-col bg-white p-5 rounded shadow-md">
            <!-- Flex container for label and button -->
            <div class="flex items-center justify-between mb-2">
              <label class="uppercase font-semibold text-sm">Quick Notes</label>
              <button
                  @click="saveChanges"
                  class="bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                Save
              </button>
            </div>

            <textarea
                v-model="local_comment.content"
                class="text-lg leading-snug w-full focus:outline-none bge-input bge-input-spacing rounded h-full"
                placeholder="Description"
            ></textarea>
          </div>


        </div>

        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="w-full mb-6">
            <div class="flex -mx-2 mr-2">
              <router-link
                  to="#information"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '' || $route.hash == '#information'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Information</span>
              </router-link>
              <router-link
                  to="#people"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#people'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>People</span>
              </router-link>
              <router-link
                  to="#addresses"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#addresses'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Addresses</span>
              </router-link>

              <router-link
                  to="#equipment"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#equipment'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Equipment</span>
              </router-link>

              <router-link
                  to="#ivy-activations"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#ivy-activations'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>SW900 iV Clients</span>
              </router-link>
              <router-link
                  to="#service-tickets"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#service-tickets'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Service Tickets</span>
              </router-link>

              <router-link
                  to="#job-sheet"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#job-sheet'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Job Sheet</span>
              </router-link>

              <router-link
                  to="#quotation"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#quotation'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Quotation</span>
              </router-link>

              <router-link
                  to="#orders"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#orders'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Orders</span>
              </router-link>

              <router-link
                  to="#documents"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#documents'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Documents</span>
              </router-link>

              <router-link
                  to="#comments"
                  class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                  :class="
                  $route.hash == '#comments'
                    ? 'bg-orange-300 text-orange-900 border-orange-300'
                    : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
                "
              >
                <span>Comments</span>
              </router-link>

              <!-- <button
                @click="currentTab = 'documents'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'documents',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'documents',
                }"
              >
                <span>Documents</span>
              </button> -->
              <!-- <button
                @click="currentTab = 'invoices'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                  'bg-orange-300 text-orange-900 border-orange-300':
                    currentTab == 'invoices',
                  'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                    currentTab != 'invoices',
                }"
              >
                <span>Invoices</span>
              </button> -->
            </div>
          </div>
          <div v-if="$route.hash == '' || $route.hash == '#information'">
            <Information :contact="contact" @refreshContact="loadContactsAndComments"/>
          </div>
          <div v-if="$route.hash == '#people'">
            <People :people="contact.people" :contact_id="contact.contact_id" @refreshContact="loadContactsAndComments"/>
          </div>
          <div v-if="$route.hash == '#addresses'">
            <Addresses :addresses="contact.addresses" :contact_id="contact.contact_id" @refreshContact="loadContactsAndComments"/>
          </div>
          <div v-if="$route.hash == '#equipment'">
            <Equipment :contactId="contact.contact_id"/>
          </div>
          <div v-if="$route.hash == '#comments'">
            <Comments ref="refComments" @refreshContact="loadContactsAndComments"
                :resourceType="'contact'"
                :resourceId="contact.contact_id"
            />
          </div>
          <div v-if="$route.hash == '#invoices'"></div>
          <div v-if="$route.hash == '#service-tickets'">
            <Tickets ref="refTickets" :contactId="contact.contact_id"/>
          </div>
          <div v-if="$route.hash == '#ivy-activations'">
            <IvyActivations :vtmsOrgId="contact.vtms_org_id"/>
          </div>
          <div v-if="$route.hash == '#job-sheet'">
            <JobSheet :contactId="contact.contact_id"/>
          </div>
          <div v-if="$route.hash == '#quotation'">
            <Quotation :contactId="contact.contact_id"/>
          </div>
          <div v-if="$route.hash == '#documents'">
            <Documents :contact="contact"/>
          </div>
          <div v-if="$route.hash == '#orders'">
            <Orders :contactId="contact.contact_id"/>
          </div>
          <div v-if="error">
            <div
                class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
            >
              <p>
                Couldn't load prospect with ID ....:
                {{ error.statusText }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
          v-else
          class="bg-white rounded shadow-md flex items-center justify-center py-20"
      >
        <spinner :color="'text-black'" :size="10"/>
      </div>
    </main>
    <portal to="overlay-outlet">
      <PDFViewer :showing="pdfViewerData.isShowingViewer" :pdfBlob="pdfViewerData.pdfData"
                 :documentName="pdfViewerData.documentName" @close="closeViewer"/>
    </portal>

    <portal to="overlay-outlet">
      <panel
          :showing="editDetailsPanelOpen"
          @close="editDetailsPanelOpen = false"
          title="Edit Contact Name and Website"
      >
        <edit-contact-details @refreshContact="() => { editDetailsPanelOpen = false; loadContactsAndComments(); }"
            :contactDetails="contactDetails"
            @complete="closeEditPanel"
        />
      </panel>
    </portal>
    <portal to="overlay-outlet">
      <Panel
          :showing="showingCreateTicketPanel"
          @close="showingCreateTicketPanel = false"
          title="Create Service Ticket"
      >
        <CreateServiceTicket
            :providedContact="contact"
            @complete="ticketCreated"
        />
      </Panel>
    </portal>
  </div>
</template>

<script>

import notificationMixin from "@/mixins/notificationMixin";
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
const PDFViewer = () => import("@/components/PDFViewer.vue");
const PageHeader = () => import("@/components/PageHeader.vue");
const Panel = () => import("@/components/Panel.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Information = () =>
    import("@/components/Contacts/Accounts/AccountsInformation.vue");
const People = () => import("@/components/Contacts/People.vue");
const Addresses = () => import("@/components/Contacts/Addresses.vue");
const Equipment = () => import("@/components/Contacts/Equipment.vue");
const Comments = () => import("@/components/Comments/Comments.vue");
const Documents = () => import("@/components/Contacts/Documents.vue");
const Tickets = () => import("@/components/Contacts/Tickets.vue");
const IvyActivations = () => import("@/components/Contacts/IvyActivations.vue");
const EditContactDetails = () =>
    import("@/components/Contacts/EditContactDetails.vue");
const CreateServiceTicket = () =>
    import("@/components/Dashboard/ServiceTickets/CreateServiceTicket.vue");

const JobSheet = () => import("@/components/Contacts/JobSheet.vue");
const Quotation = () => import("@/components/Contacts/Quotation.vue");
const Orders = () => import("@/components/Contacts/Orders.vue");

export default {
  name: "AccountsView",
  mixins: [notificationMixin],
  components: {
    Icon, AppButton,
    PageHeader,
    Panel,
    Spinner,
    Information,
    People,
    Addresses,
    Equipment,
    Comments,
    Documents,
    Tickets,
    IvyActivations,
    EditContactDetails,
    CreateServiceTicket,
    JobSheet,
    Quotation,
    Orders,
    PDFViewer,
  },
  props: {
    contactId: String,
  },
  data() {
    return {
      isBusy: false,
      error: null,
      editDetailsPanelOpen: false,
      contactDetails: {},
      showingCreateTicketPanel: false,
      local_comment:[],
      contact:null,
      comments:[],
      pdfViewerData: {
        documentName: "",
        pdfData: null,
        isShowingViewer: false,
      },

    };
  },

  async mounted() {
    this.isBusy=true;
    await this.loadContactsAndComments();
    this.initializeLocalComment();
    this.isBusy=false;

  },
  watch: {
    comments: {
      handler() {
        this.initializeLocalComment();
      },
      immediate: true,
    },
  },
  methods: {

    async loadContactsAndComments(){
          try {
            debugger;
          let result = await this.ContactService.getContactById(this.contactId);
          this.contact = result;

          let resultComments = await this.CommentService.getCommentByResourceIdAndTypeId(
              this.contactId,
              "contact"
          );
          this.comments = resultComments.data;

          if (this.$refs.refComments && typeof this.$refs.refComments.loadComments === "function") {
              await this.$refs.refComments.loadComments();
          }

          console.log(this.contact);
        } catch (error) {
          console.error(error);
        }
    },

    initializeLocalComment() {
      this.local_comment = this.comments.length > 0 ? { ...this.comments[this.comments.length - 1] } : { content: "" };
    },
    changeViewMode: function () {
      this.$emit("changeViewMode");
    },
    openEditPanel: function () {
      this.contactDetails.name = this.contact.name;
      this.contactDetails.website = this.contact.website;
      this.contactDetails.contact_id = this.contact.contact_id;
      this.editDetailsPanelOpen = true;
    },
    closeEditPanel: function () {
    },
    formatDefaultAddress: function () {
      if (!this.contact.addresses || this.contact.addresses.length == 0) {
        return ``;
      }

      let defaults = this.contact.addresses.filter((addr) => addr.is_default);
      let address =
          defaults.length > 0 ? defaults[0] : this.contact.addresses[0];

      return [
        address.address1,
        address.address2,
        address.address3,
        address.city,
        address.postcode,
      ]
          .filter((part) => !!part)
          .join(", ");
    },
    ticketCreated: async function () {
      this.showingCreateTicketPanel = false;
      await this.refreshTickets();
    },
    refreshTickets: async function () {
      await this.$refs.refTickets.loadTickets();
    },
    formatCurrency(value) {
      const number = parseFloat(value);
      if (isNaN(number)) return '£0.00'; // Handle non-numeric inputs safely

      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(number);
    },

    async saveChanges() {
      try {
        if (this.local_comment.comment_id) {
          // Update existing comment
          await this.CommentService.updateComment(this.local_comment);

          // Refresh comments from the server
          const result = await this.CommentService.getCommentByResourceIdAndTypeId(
              this.contact.contact_id,
              "contact"
          );
          this.comments = result.data;
        } else {
          // Create new comment
          const result = await this.CommentService.createComment(
              this.contact.contact_id,
              "contact",
              this.local_comment
          );
          // this.comments.unshift(result);
        }
        this.notifySuccess("Comment saved successfully");
       await this.loadContactsAndComments();
      } catch (error) {
        this.notifyError("Couldn't save comment");
        console.error(error);
      }
    },


    closeViewer() {
      this.pdfViewerData = {
        pdfData: null,
        documentName: "",
        isShowingViewer: false,
      };
    },

    async printContactReport() {
      if (!this.contact.contact_id) {
        this.notifyWarning("Missing contact ID. Please ensure all required fields are filled.");
        return;
      }

      try {
        const response = await this.ContactService.generateContactReport(this.contact.contact_id, 'contact-report');

        this.pdfViewerData = {
          pdfData: response,
          documentName: 'Contact Report',
          isShowingViewer: true,
        };
      } catch (error) {
        console.error('Error loading PDF:', error);
        this.notifyWarning("Failed to load the PDF document. Please try again.");
      }
    },



  },
};
</script>
